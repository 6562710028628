<template>
  <div class="ml-1" v-if="source !== null">
    <!-- Title area -->
    <div v-show="editmodeTitle" :id="'itemcollapse' + source._id">
      <div>
        <input
          autofocus
          tabindex="1"
          ref="headingTitle"
          type="text"
          :readonly="!authorization.update"
          v-model="source.title"
          placeholder="Heading title here..."
          class="custom-heading text-left smallcaps mt-3"
          @blur="save"
          @keydown.enter="save"
        />
      </div>
    </div>
    <div
      v-show="!editmodeTitle"
      :id="source._id"
      data-type="heading"
      class="ma-0 navigate"
      @click="highlightHeading(source, true)"
    >
      <h4 class="hoveritemTitle smallcaps mt-3">
        {{ source.title }}
      </h4>
    </div>
    <v-divider v-show="divider" class="mt-1 mr-1" />
  </div>
</template>
<script>
export default {
  name: 'HeadingEditor',
  props: {
    view: Object,
    item: Object,
    properties: Object,
    authorization: Object,
    divider: {
      type: Boolean,
      default: false
    }
  },
  components: {},
  data () {
    return {
      editmodeTitle: false,
      olditem: null,
      source: null
    }
  },
  mounted () {
    this.olditem = Object.assign({}, this.item)
    this.source = Object.assign({}, this.item)
  },
  computed: {},
  methods: {
    save () {
      if (this.olditem.title !== this.source.title) {
        this.view.put(this.source)
        this.olditem.title = this.source.title
      }
      this.highlightHeading(this.source, false)
    },
    highlightHeading (item, mode) {
      if (this.authorization.update) {
        this.editmodeTitle = item.type === 'heading' ? mode : false
        this.highlight(item)
      }
    },
    highlight (item) {
      this.$nextTick(() => {
        localStorage.setItem('selected', item._id)
        var element = document.getElementById(item._id)
        var els = document.querySelectorAll('.navigate')
        for (var i = 0; i < els.length; i++) {
          els[i].classList.remove('selected')
        }
        element.classList.add('selected')
        this.$emit('sync', item._id)
        this.$refs.headingTitle.focus()
      })
    }
  },
  watch: {
    editmodeTitle: {
      handler: function (newVal, oldVal) {
        if (newVal !== oldVal) {
          this.$emit('editmode', newVal)
        }
      }
    }
  }
}
</script>

<style scoped>
.hoveritemDesc {
  width: 100%;
  position: relative;
}
.hoverbuttonDesc {
  display: none;
  position: absolute;
  bottom: 0;
  right: 0;
}
.hoveritemDesc:hover .hoverbuttonDesc {
  display: inline-block;
}
.theme--light .selected {
  background-color: rgb(21, 101, 192, 0.4) !important;
}
.theme--dark .selected {
  background-color: rgb(239, 108, 0, 0.4) !important;
}
</style>
